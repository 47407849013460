import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Field as TField } from 'o365.modules.DataObject.Fields.ts';
import type { CrudHandler as TCrudHandler } from 'o365.pwa.declaration.sw.CrudHandler.d.ts';
import type {
    ApiDataRequestOptions as TApiDataRequestOptions,
    IApiDataRequestRetrieveOperationBody,
    IApiDataRequestCreateOperationBody,
    IApiDataRequestUpdateOperationBody,
    IApiDataRequestDestroyOperationBody,
} from 'o365.pwa.declaration.sw.apiRequestOptions.ApiDataRequestOptions.d.ts';
import type { IO365FlatOfflineDataRecord } from 'o365.pwa.declaration.sw.O365OfflineDataRecord.d.ts';
import type { StrategyOptions, StrategyHandler } from 'o365.pwa.declaration.sw.workbox.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

// Interfaces
export interface IApiDataStrategyOptions extends StrategyOptions { }
export interface IParsedRequest {
    request: Request;
    requestClone: Request;
    headers: IParsedHeaders;
    requestCloneJsonBody: RequestBodyUnion;
}
export interface IParsedHeaders {
    hasOfflineSyncIsOfflineData: boolean;
    hasAppStateOverride: boolean;
    offlineSyncIsOfflineDataValue: string;
    appStateOverrideValue: string;
}
export interface IProcedureRequestBody {
    operation: string;
    procedureName: string;
    useAlert?: boolean;
    useTransaction?: boolean;
    timeout?: number;
    dataObjectId?: string;
    maxRecords?: number;
    skip?: number;
    fields?: Array<TField>;
}
export interface IRowCountResponse {
    total: number;
    skip: number;
}

// Types
export type RequestBodyUnion = IProcedureRequestBody /* | typeof RecordSource */;

(() => {
    const { ApiDataRequestOptions } = self.o365.importScripts<{ ApiDataRequestOptions: typeof TApiDataRequestOptions }>("o365.pwa.modules.sw.apiRequestOptions.ApiDataRequestOptions.ts");
    const { CrudHandler } = self.o365.importScripts<{ CrudHandler: typeof TCrudHandler }>("o365.pwa.modules.sw.CrudHandler.ts");

    class ApiDataStrategy extends self.workbox.strategies.Strategy {
        constructor(options: IApiDataStrategyOptions) {
            super(options);
        }

        async _handle(request: Request, handler: StrategyHandler): Promise<Response> {
            const options = await ApiDataRequestOptions.fromRequest(request);
            const parsedOptions = options.parsedOptions;

            if (!parsedOptions.headers.get('o365-app-state-override')?.has('OFFLINE')) {
                // Fetch from the network unless explicitly targeting offline DB
                return await handler.fetch(request);
            }

            try {
                let responseData;

                switch (parsedOptions.body.operation) {
                    case 'retrieve':
                        responseData = await this.handleRetrieve(parsedOptions);
                        break;
                    case 'rowcount':
                        responseData = await this.handleRetrieveRowcount(parsedOptions);
                        break;
                    case 'create':
                        responseData = await this.handleCreate(parsedOptions);
                        break;
                    case 'update':
                        responseData = await this.handleUpdate(parsedOptions);
                        break;
                    case 'destroy':
                        responseData = await this.handleDestroy(parsedOptions);
                        break;
                    default:
                        throw new Error('Not implemented');
                }

                return new Response(JSON.stringify({ success: responseData }), {
                    status: 200,
                    statusText: 'OK',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    })
                });
            } catch (reason) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));
                
                const responseBody = {
                    error: stringifiedReason
                };

                return new Response(JSON.stringify(responseBody), {
                    status: 500,
                    statusText: 'Internal Server Error',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    })
                });
            }
        }

        private async handleRetrieve(parsedOptions: any): Promise<any> {

            const retrieveBody = parsedOptions.body as IApiDataRequestRetrieveOperationBody;
            const retrieveResponse = await CrudHandler.handleRetrieve({
                appId: parsedOptions.appId,
                objectStoreId: retrieveBody.dataObjectId,
                fields: retrieveBody.fields,
                distinctRows: retrieveBody.distinctRows, 
                filterObject: retrieveBody.filterObject,
                indexedDbWhereExpression: retrieveBody.indexedDbWhereExpression,
                maxRecords: retrieveBody.maxRecords,
                appIdOverride: retrieveBody.appIdOverride,
                databaseIdOverride: retrieveBody.databaseIdOverride,
                skip: retrieveBody.skip,
                whereObject: retrieveBody.whereObject,
                masterDetailObject: retrieveBody.masterDetailObject,
                objectStoreIdOverride: retrieveBody.objectStoreIdOverride,
            });
            return retrieveResponse;
        }

        private async handleRetrieveRowcount(parsedOptions: any): Promise<any> {

            const rowCountBody = parsedOptions.body as IApiDataRequestRetrieveOperationBody;
            const rowcount = await CrudHandler.handleRetrieveRowcount({
                appId: parsedOptions.appId,
                dataObjectId: rowCountBody.dataObjectId,
                fields: rowCountBody.fields,
                distinctRows: rowCountBody.distinctRows,
                filterObject: rowCountBody.filterObject,
                whereObject: rowCountBody.whereObject,
                maxRecords: rowCountBody.maxRecords,
                skip: rowCountBody.skip,
                objectStoreIdOverride: rowCountBody.objectStoreIdOverride,
                indexedDbWhereExpression: rowCountBody.indexedDbWhereExpression!,
            });
            return { total: rowcount, skip: 0 };
        }

        private async handleCreate(parsedOptions: any): Promise<any> {
            const createBody = parsedOptions.body as IApiDataRequestCreateOperationBody;
            const createRecord = createBody.values as IO365FlatOfflineDataRecord;

            const createResponse = await CrudHandler.handleCreate({
                appId: parsedOptions.appId,
                dataObjectId: createBody.dataObjectId,
                appIdOverride: createBody.appIdOverride,
                databaseIdOverride: createBody.databaseIdOverride,
                objectStoreIdOverride: createBody.objectStoreIdOverride,
                fields: createBody.fields,
                providedRecord: createRecord,
            });
            return [createResponse];
        }

        private async handleUpdate(parsedOptions: any): Promise<any> {

            const updateBody = parsedOptions.body as IApiDataRequestUpdateOperationBody;
            const updateRecord = updateBody.values as IO365FlatOfflineDataRecord;
            const updateResponse = await CrudHandler.handleUpdate({
                appId: parsedOptions.appId,
                dataObjectId: updateBody.dataObjectId,
                appIdOverride: updateBody.appIdOverride,
                databaseIdOverride: updateBody.databaseIdOverride,
                objectStoreIdOverride: updateBody.objectStoreIdOverride,
                providedRecord: updateRecord,
            });
            return [updateResponse];
        }

        private async handleDestroy(parsedOptions: any): Promise<any> {

            const destroyBody = parsedOptions.body as IApiDataRequestDestroyOperationBody;
            const destroyRecord = destroyBody.values as IO365FlatOfflineDataRecord;
            const destroyResponse = await CrudHandler.handleDestroy({
                appId: parsedOptions.appId,
                dataObjectId: destroyBody.dataObjectId,
                appIdOverride: destroyBody.appIdOverride,
                databaseIdOverride: destroyBody.databaseIdOverride,
                objectStoreIdOverride: destroyBody.objectStoreIdOverride,
                providedRecord: destroyRecord,
            });
            return destroyResponse;
        }
    }

    self.o365.exportScripts({ ApiDataStrategy });
})();